<template>
  <div class="mains">
    <el-container>
      <el-main class="main">
        <div class="nav_bg">
          <div class="nav" ref="nav">
            <!-- <div class="logo">LOGO</div>
            <div class="divider"></div> -->
            <div class="tab_list">
              <el-menu class="tab_menu" :default-active="toIndex" active-text-color="#FFD013" mode="horizontal" @select="handleSelect">
                <el-menu-item class="tab_item" v-for="(item, index) in menuList" :key="index" :index="item.menuPath">{{ item.menuName }}</el-menu-item>
              </el-menu>
              <div class="search">
                <el-input v-model="search" placeholder="请输入内容">
                  <el-button slot="suffix" icon="el-icon-search" round>搜索</el-button>
                </el-input>
              </div>
            </div>
          </div>
        </div>

        <transition mode="out-in" appear>
          <router-view />
        </transition>
      </el-main>
    </el-container>

    <footers />
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeTab: "/",
      menuList: [],
      search: '',
    };
  },
  created () {
    this.menuList = this.$store.state.menuList
  },
  // mounted () {
  //   window.addEventListener('scroll', this.handleScroll)
  // },
  // destroyed () {
  //   window.removeEventListener('scroll', this.handleScroll)
  // },
  computed: {
    toIndex () {
      if (this.$route.path == '/') {
        this.$router.replace(this.menuList[0].menuPath)
        return `${this.menuList[0].menuPath}`;
      } else {
        return `/${this.$route.path.split("/")[1]}`;
      }
    },
  },
  methods: {
    // 切换菜单
    handleSelect (index, path) {
      this.activeTab = path.join();
      this.$router.push({ path: path.join() });
    },
    // 切换更多中的菜单
    changeTab (e) {
      this.activeTab = e.path;
      this.$router.push({ path: e.path });
    },
    // 监听页面滚动
    // handleScroll () {
    //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //   if (scrollTop > 460) {
    //     this.$refs.nav.classList.add('nav_active');
    //   } else {
    //     this.$refs.nav.classList.remove('nav_active');
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.mains {

  .main {
    height: 100%;
    padding: 0;
  }

  .nav_bg {
    width: 100%;
    height: 70px;
    background: #2E6CD7;
    z-index: 999;
  }

  .nav_active {
    color: #000 !important;

    .divider {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }

    .tab_item {
      color: #000 !important;
    }

    .is-active {
      color: #FFD013 !important;
    }
  }

  .nav {
    display: flex;
    width: 1280px;
    margin: 0 auto;
    color: #fff;
    z-index: 999;

    // .logo {
    //   height: 70px;
    //   line-height: 70px
    // }

    // .divider {
    //   width: 1px;
    //   height: calc(70px - 32px);
    //   margin: 16px;
    //   background-color: rgba(255, 255, 255, 0.5);
    // }

    .tab_list {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;

      .tab_menu {
        flex: 1;
        border: none;
        background: transparent;

        .tab_item {
          position: relative;
          height: 70px;
          line-height: 70px;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }

        .el-menu--horizontal>.el-menu-item.is-active {
          border: none;
        }

        .is-active::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          border-radius: 10px 10px 0 0;
          background-color: #FFD013;
          transform: all 0.3s;
        }
      }

      .search {
        width: 270px;

        /deep/.el-input__inner {
          color: #fff;
          border-radius: 20px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          background: rgba(255, 255, 255, 0.2);
        }

        /deep/.el-input__suffix {
          right: 0;
        }

        /deep/.el-button {
          width: 90px;
          color: #fff;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
